import telegram from "../assets/telegram.png";
import xcom from "../assets/xcom.png";
import dexscreener from "../assets/dexscreener.png";
import { motion } from "framer-motion";

function About() {
  return (
    <div className="flex flex-col justify-center items-center max-w-2xl mx-auto">
      <div className="flex flex-wrap mt-20 gap-3 md:text-lg font-medium justify-center">
        <a
          className="bg-white py-6 px-8 rounded-full border-[2px] border-black"
          href="https://t.me/FELONSonSOL"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={telegram} width={40} />
        </a>
        <a
          className="bg-white py-6 px-8 rounded-full border-[2px] border-black"
          href="https://x.com/FELONSonSOLANA"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={xcom} width={40} />
        </a>
        <a
          className="bg-white py-6 px-8 rounded-full border-[2px] border-black"
          href="https://dexscreener.com/solana/contract"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={dexscreener} width={40} />
        </a>
      </div>
      <div className="text-center">
        <p className="text-lg py-12">
          <span className="text-xl">
            Trump, Sam Bankman-Fried & Changpeng Zhao all in one cell. <br />
            And yes, Caroline Ellison still comes for conjugal visits.
          </span>
        </p>
      </div>
    </div>
  );
}
export default About;
