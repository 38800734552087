import About from "../components/About";
import Services from "../components/Services";

function Main() {
  return (
    <>
      <main className="w-[90%] mx-auto">
        <About />
        <Services />
      </main>
    </>
  );
}
export default Main;
