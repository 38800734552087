import gang from "../assets/group.png";

function Services() {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-center pt-5 pb-6 w-[80%] font-semibold bg-white rounded-full max-w-lg">
        4tcCArk6iw3HaKXhu9CfkiNxjY3hJHAh1K12bR2Fpump
      </div>
      <div className="p-4">
        <h3 className="font-black text-md text-center">
          *Contract Renounced! Tax 0/0, Liquidity locked *
        </h3>
      </div>
      <img className="w-[80%] max-w-xl" src={gang} />
    </div>
  );
}
export default Services;
