import React from "react";
import jailbg from "../assets/jail-bg.png";
import trump from "../assets/tremp.png";
import group from "../assets/group.png";
import bars from "../assets/bars.png";
import logo from "../assets/$FELONS.png";
import { motion } from "framer-motion";

function Header() {
  return (
    <header className="relative overflow-hidden w-full h-[80vh] flex flex-col justify-center items-center bg-[#CBE9E1]">
      <div className="relative w-full h-full brightness-50 md:brightness-100">
        <img
          src={jailbg}
          alt="mansion background"
          className="w-full h-full object-cover absolute top-0 left-0 z-0"
        />
        <img
          src={trump}
          alt="mp"
          className="object-contain absolute bottom-10 left-1/2 transform -translate-x-1/2 z-1"
        />
      </div>
      <div className="absolute w-full h-full flex flex-col">
        <motion.img
          initial={{ opacity: 0, y: "-100%" }}
          whileInView={{ opacity: 1, y: "0px" }}
          transition={{ duration: 2, delay: 0.2 }}
          viewport={{ once: true }}
          src={bars}
          alt="bars"
          className="h-full object-cover drop-shadow-lg z-2"
        />
      </div>
      <div className="absolute flex items-start w-full h-full flex-col overflow-visible">
        <motion.img
          initial={{ opacity: 0, y: "100%" }}
          whileInView={{ opacity: 1, y: "0" }}
          transition={{ duration: 1.5, delay: 2 }}
          viewport={{ once: true }}
          src={logo}
          alt="logo"
          className="mx-auto mt-20 w-[80%] max-w-xl object-cover drop-shadow-lg z-2"
        />
      </div>
      <div className="flex md:hidden absolute items-start w-full h-full flex-col overflow-visible">
        <motion.img
          initial={{ opacity: 0, y: "20%" }}
          whileInView={{ opacity: 1, y: "0" }}
          transition={{ duration: 1.5, delay: 1 }}
          viewport={{ once: true }}
          src={group}
          alt="logo"
          className="mx-auto mt-56 w-[80%] max-w-xl object-cover drop-shadow-lg z-2"
        />
      </div>
      <a
        href="https://pump.fun/4tcCArk6iw3HaKXhu9CfkiNxjY3hJHAh1K12bR2Fpump"
        className="absolute bottom-24 z-50 text-xl text-center shadow-xl max-w-md text-[#333333] font-semibold bg-white pt-3 pb-4 w-[80%] rounded-full"
        target="_blank"
      >
        Buy on Pump.fun
      </a>
    </header>
  );
}

export default Header;
