import React, { useState, useCallback } from "react";
import Navbar from "./components/Navbar";
import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import AudioContext from "./components/AudioContext";

function App() {
  const [isEntered, setIsEntered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handleEnter = () => {
    setIsEntered(true);
    setIsPlaying(true);
  };

  const toggleAudio = useCallback(() => {
    setIsPlaying((prev) => !prev);
  }, []);

  return (
    <AudioContext.Provider value={{ isPlaying, toggleAudio }}>
      {!isEntered && (
        <div className="fixed inset-0 bg-orange-500 flex items-center justify-center z-50">
          <button
            onClick={handleEnter}
            className="text-xl font-bold py-6 px-12 bg-white text-[#333333] rounded hover:bg-blue-700 transition duration-300"
          >
            Enter the Cells
          </button>
        </div>
      )}

      {isEntered && (
        <>
          <Navbar />
          <Header />
          <Main />
          <Footer />
        </>
      )}
    </AudioContext.Provider>
  );
}

export default App;
