import React from "react";
import logo from "../assets/logo-outline.svg";
import sam from "../assets/sam.png";
import cz from "../assets/cz.png";

let array = [1, 2, "a", 3, "b"];

function numsOnly(list) {
  list.filter((num) => typeof num === "number");

  console.log(list);
  return list;
}

function Footer() {
  return (
    <footer className="text-white py-8 flex flex-col items-center justify-center w-[80%] mx-auto">
      <div className="flex flex-col md:flex-row items-center justify-between">
        <div className="flex flex-wrap gap-6 md:text-lg font-medium justify-center">
          <a
            href="https://t.me/FELONSonSOL"
            target="_blank"
            rel="noopener noreferrer"
          >
            Telegram
          </a>
          <a
            href="https://x.com/FELONSonSOLANA"
            target="_blank"
            rel="noopener noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://dexscreener.com/solana/contract"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dexscreener
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
