import React, { useContext, useEffect, useRef } from "react";
import AudioContext from "./AudioContext";

function Navbar() {
  const { isPlaying, toggleAudio } = useContext(AudioContext);
  const audioRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise
          .then(() => {})
          .catch((error) => {
            console.error("Error occurred during playback:", error);
          });
      }
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <nav className="absolute w-full top-0 z-50">
      <div className="container mx-auto px-4 py-2 flex items-center justify-between">
        <audio ref={audioRef} src="/song.mp3" loop></audio>
        <button
          onClick={toggleAudio}
          className="text-3xl py-4 font-bold text-white"
        >
          {isPlaying ? "Pause" : "Play"}
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
